import { getCookie } from 'cookies-next';

const useCheckCookies = () => {
  const isFlagAvailable = getCookie('x-disabled-recaptcha');
  const accessToken = getCookie('admin-portal-access-token');
  const isRecaptchaDisabled = Boolean(isFlagAvailable);
  const isLoginTokenAvailable = Boolean(accessToken);
  return {
    isRecaptchaDisabled,
    isLoginTokenAvailable,
  };
};

export default useCheckCookies;
