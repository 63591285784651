'use client';

import { FC, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Typography, Grid } from '@mui/material';
import { Button } from '@components/ButtonMui/Button';
import useCheckCookies from '@src/hooks/useCheckCookies';
import Image from 'next/image';
const classes = {
  container: 'flex flex-col justify-center items-center min-w-screen min-h-screen bg-slate-900',
  text: 'text-center text-white text-base leading-7',
  backButton: 'mt-20'
};
const NotFound: FC = () => {
  const router = useRouter();
  const {
    isLoginTokenAvailable
  } = useCheckCookies();
  const handleBackHome = () => {
    if (isLoginTokenAvailable) {
      router.push('/');
      return;
    }
    router.push('/logout');
  };

  // Using useEffect for any side effects or async operations
  useEffect(() => {
    const performRedirect = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (!isLoginTokenAvailable) {
        router.push('/');
      }
    };
    performRedirect();
  }, [isLoginTokenAvailable, router]);
  return <Grid container className={classes.container} data-sentry-element="Grid" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <Image src="/assets/images/404.svg" alt="access-denied-icon" width={500} height={450} className="mb-32" data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
      <Grid item direction="column" container alignItems="center" justifyContent="center" data-sentry-element="Grid" data-sentry-source-file="not-found.tsx">
        <Typography className={classes.text} data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
          We&apos;ve noticed your lost your way, not to worry though,
        </Typography>
        <Typography className={classes.text} data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
          We can help you find your next opportunity
        </Typography>
        <Button onClick={handleBackHome} variants="primary" className={classes.backButton} data-sentry-element="Button" data-sentry-source-file="not-found.tsx">
          Go Home
        </Button>
      </Grid>
    </Grid>;
};
export default NotFound;