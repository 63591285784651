import { IDefaultColors } from '@src/types/colors';

const colors = require('tailwindcss/colors');

const colorsToOmit = [
  'inherit',
  'current',
  'transparent',
  'lightBlue',
  'warmGray',
  'trueGray',
  'coolGray',
  'blueGray',
];

const filteredColors: IDefaultColors = {};
for (const key in colors) {
  if (colors.hasOwnProperty(key) && !colorsToOmit.includes(key)) {
    filteredColors[key] = colors[key];
  }
}

const COLORS: IDefaultColors = {
  // WHITE use default preset
  ...filteredColors,
  white: '#ffffff', // WHITE
  gray: {
    ...filteredColors.gray,
    '200': '#E5E7EB', // WHITE2
    '400': '#A3B1BE', // SHADEGREY
    '700': '#31374A', // BLUE4
    '900': '#111827', // RED6, PURPLE2, GREEN3, BLUE5
  },
  slate: {
    ...filteredColors.slate,
    '700': '#2A3044', // BLUE2
    '800': '#24293C', // BLUE3, BLUE9
    '900': '#181D2F', // BLUE1,
  },
  sky: {
    ...filteredColors.sky,
    '700': '#0064BD', // BLUE6
    '800': '#004B8F', // BLUE10
  },
  blue: {
    ...filteredColors.blue,
    '400': '#5A9AFE', // BLUE7, BLUE8
  },
  red: {
    ...filteredColors.red,
    '500': '#F44336', // RED1, RED2, RED3, RED7, RED8
    '600': '#DE3818', // RED5
  },
  rose: {
    ...filteredColors.rose,
    '400': '#F95584', // PINK2
    '500': '#FF4A66', // RED4
  },
  lime: {
    ...filteredColors.lime,
    '700': '#2F871A', // GREEN1
  },
  green: {
    ...filteredColors.lime,
    '400': '#43E882', // GREEN4
    '500': '#1ED062', // GREEN2
  },
  emerald: {
    ...filteredColors.emerald,
    '500': '#11B769', // GREEN5
  },
  neutral: {
    ...filteredColors.neutral,
    '400': '#8F8F8F', // GRAY1, GRAY2
  },
  zinc: {
    ...filteredColors.zinc,
    '400': '#8E8E93', // GREY1
  },
  stone: {
    ...filteredColors.stone,
    '300': '#BFBFBF', // GREY2
    '900': '#282214', // YELLOW3 (IN_PROGRESS background)
  },
  yellow: {
    ...filteredColors.yellow,
    '300': '#E8CD43', // YELLOW6
    '950': '#2E2401', // YELLOW1, YELLOW5
  },
  amber: {
    ...filteredColors.amber,
    '400': '#FEC140', // YELLOW2, YELLOW4 (for status IN_PROGRESS color)
  },
  indigo: {
    ...filteredColors.indigo,
    '500': '#6863FE', // PURPLE1
  },
  purple: {
    ...filteredColors.purple,
    '500': '#A96CFF', // PURPLE3
  },
};

export default COLORS;
